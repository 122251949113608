export default{
  banner: {
    titleImg: require('../images/trill/banner_title.png'),
    video:{
      src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/3e62afd8-17920bfc461-0005-502e-0e5-a9c95.mp4',
      poster: require('../images/public/poster/trill.jpg'),
      title: '',
      dec: '门店做抖音，绝不仅仅是做粉丝量，更重要的是吸引准确的客户来店里买单。如何最低成本在抖音获客，黑谷专业团队可以帮你。原本发不出去的传单，在抖音统统可以实现。抓住抖音红利期，把自己的门店做成抖音网红店是实体行业新的出路。'
    }
  },
  cards:[
    {
      title: '如何3个月时间在抖音上成为网红店',
      content: '各行各业都在学习做抖音，很多人花了很多时间精力和成本在做，但效果都不理想。那实体店怎么玩抖音，才能快速的实现引流变现呢？黑谷抖音运营团队从“教你做”变成“帮你做”。从前期定位和策划，到文案撰写到视频剪辑，到投放运营黑谷都会帮你完成。经过黑谷上百人的抖音团队专业的运作，让你的视频可以精准的投放到6公里范围内的精准客户，吸引你门店周边的优质高端客户到店消费，同时帮助你唤醒已经流失或者沉睡的老客户再次到店，以前发不出去的传单，通过抖音运营做到了，效果更好，而且成本更低，1分投入收获更多回报。'
    }
  ],
  questions:{
    title: '帮助门店解决哪些问题',
    list: [
      {
        icon: 'trill_01',
        title: '精准拓展新客',
        content: '实体店做抖音不是粉丝越多越好，而是越精准越好。那什么样的客户是你的精准客户呢？第一，离你门店近的 ，第二，对你的产品感兴趣的。第三，有消费能力的。那么现在通过抖音的后台就可以筛选出你门店周边对你的门店产品感兴趣的客户，通过黑谷运营团队的运作，可以让这些精准的客户群体，一个月至少6次刷到你门店的视频，并且刷到的同时，他还可以领取一张优惠券，精准引流客户到店消费。'
      },
      {
        icon: 'trill_02',
        title: '定向唤醒老客',
        content: '门店有些老客户之前经常来，后面慢慢就不来了，怎么让这些客户再返店消费呢？传统的方式给客户发短信发微信，客户可能都不会看。但是今天你可以让他一打开抖音，立马就看到了你家的视频。只要把这些客户的手机号码，导入到抖音后台再进行投放操作，这些手机号码的客户一周至少可以两次都可以刷到你准备好的视频，那很多很久没到店的老客户就会再次被我们唤醒，很快又会回店消费。'
      },
      {
        icon: 'trill_03',
        title: '门店活动推广',
        content: '一个活动是否能引起消费者兴趣，黑谷团队帮你设计。根据门店的调性，根据消费者的喜好、消费习惯设置优惠活动，深入研究不同的门店产品的投放时间点及投放策略，让目标用户可以高频看到，并吸引客户进店消费。'
      },
      {
        icon: 'trill_04',
        title: '促进口碑裂变',
        content: '通过抖音运营，让门店品牌在周边目标用户群里增加曝光度，专业团队打造有品质有内容的视频帮助门店树立品牌形象，促进口碑裂变。'
      }
    ]
  },
  services: {
    title: '黑谷服务内容',
    dec: '专业抖音运营团队从教你做到帮你做',
    list: [
      {
        title: '账号搭建',
        content: [
          {
            value: '① 申请抖音账号'
          },
          {
            value: '② 申请蓝V认证'
          },
          {
            value: '③ 账号装饰'
          },
          {
            value: '④ 账号充值'
          }
        ]
      },
      {
        title: '内容设计',
        content: [
          {
            label: '门店活动推广：',
            value: '专业定制的门店活动视频可在指定时段，筛选对活动内容感兴趣的目标人群多次重复展现，吸引准确的受众参与活动。'
          },
          {
            label: '项目类视频：',
            value: '为什么要做这个项目？做这个项目的好处是什么？不做这个项目的坏处是什么？'
          },
          {
            label: '展示核心优势视频：',
            value: '展示跟别人不一样的优势从差异化的维度进行着手'
          }
        ]
      },
      {
        title: '脚本产生',
        content:[
          {
            label: '涨粉脚本：',
            value: '根据行业属性定向设计'
          },
          {
            label: '知识脚本：',
            value: '根据行业属性定向设计'
          },
          {
            label: '转化脚本：',
            value: '根据行业属性定向设计'
          },
          {
            label: '案例脚本：',
            value: '根据门店提供对应素材进行定向设计'
          }
        ]
      },
      {
        title: '拍摄画面',
        content:[
          {
            label: '提供示范视频：',
            value: '感受全片，全方位了解视频生成过程'
          },
          {
            label: '提供拍摄教程：',
            value: '如，门头怎么拍？细节如何拍？'
          },
          {
            label: '提供分镜脚本：',
            value: '根据分镜脚本指定拍摄相关画面回传'
          }
        ]
      },
      {
        title: '视频产生',
        content: [
          {
            label: '后期剪辑制作：',
            value: '根据脚本，进行剪辑、字幕、配音，定制属于门店专属的结束语、结束画面'
          }
        ]
      },
      {
        title: '投放运营',
        content: [
          {
            label: '根据产品的优势定制投放策略：',
            value: '如，涨粉视频和转化视频什么时间投？投什么人群？等等'
          }
        ]
      },
      {
        title: '结果反馈',
        content: [
          {
            label: '投放结果及时反馈：',
            value: '按周，月为周期，把制作多少视频，观看、点赞、评论、私信等内容以报表形式反馈给到门店'
          },
          {
            label: '实时调整投放战略：',
            value: '根据门店的实际效果，微信、私信、到店、转化，营业额等进行及时的投放战略调整'
          }
        ]
      }
    ]
  },
  editors: {
    title: '黑谷抖音事业部-编导团队',
    dec: '',
    teams: [
      {
        avatar: require('../images/trill/editor/01.jpg'),
        name: '林舒悦',
        tag: '顶级制片人',
        intro: '毕业于台湾龙华科技大学，导演经验丰富、能力优异，曾获[台湾微电影竞赛]第一名、[全国广告艺术大赛]三等奖、[全球华人时报金犊奖]视频类优等奖， 3个月内将0粉账号孵化为百万ip。'
      },
      // {
      //   avatar: require('../images/trill/editor/02.jpg'),
      //   name: '李逸铅',
      //   tag: '高级制片人',
      //   intro: '从事影视编导行业3年，涉猎自媒体行业2年，指导拍摄过多部高口碑，高收视现象级纪录片，孵化过百万粉丝ip账号，无论企业宣传、市政宣传，或是视频制作方面，都能提供专业指导和支持。'
      // },
      {
        avatar: require('../images/trill/editor/03.jpg'),
        name: '王李炜',
        tag: '创意导演',
        intro: '4年新媒体人熟悉各大短视频平台运作规则和流量机制擅长ip打造和营销策划从0到1，孵化与运营百万级达人账号3个30w级美食账号2个合作品牌商家20+，快速实现商业变现'
      },
      {
        avatar: require('../images/trill/editor/04.jpg'),
        name: '郑函戎',
        tag: '策划导演',
        intro: '从事短视频编剧策划行业6年，熟悉各大视频平台制作模式，擅长视频内容策划编辑。策划拍摄故事类纪录片在短视频平台投放，总播放量超2.5亿。合作拍摄实力商家超二十家，被阿里巴巴授权拍摄淘宝商家成长计划等。'
      },
      // {
      //   avatar: require('../images/trill/editor/05.jpg'),
      //   name: '赵倩',
      //   tag: '高级内容总监',
      //   intro: '毕业于青岛大学资深新媒体编辑互联网经验丰富文案风格独特，自成一派商家引流效果好，促单量极高'
      // },
      // {
      //   avatar: require('../images/trill/editor/06.jpg'),
      //   name: '陈集华',
      //   tag: '顶高级内容总监',
      //   intro: '4年自媒体从业经验资深美食策划现场采编能力极强参与“看见集美” “厦门大学百年校庆” “2020年金鸡百花电影节”系列活动'
      // },
      {
        avatar: require('../images/trill/editor/07.jpg'),
        name: '王华炜',
        tag: '新媒体编导',
        intro: '从事自媒体行业成功孵化百万级IP并有破千万播放量的视频创作经验'
      },
      {
        avatar: require('../images/trill/editor/08.jpg'),
        name: '邹倡',
        tag: '创意编导',
        intro: '毕业于哈尔滨商业大学2年短视频编导项目经验擅长ip打造和流量运营孵化百万级美食账号2个，汽车类账号1个全网千万粉丝，播放量破亿'
      }
    ]
  },
  writers: {
    title: '黑谷抖音事业部-编导团队',
    dec: '',
    teams: [
      // {
      //   avatar: require('../images/trill/writes/01.jpg'),
      //   name: '张玫',
      //   tag: '创意编剧',
      //   intro: '一线餐饮行业出身擅长商家卖点抓取和产品包装完成众多品牌的新媒体宣传单篇软文带来销售额高达60W+'
      // },
      // {
      //   avatar: require('../images/trill/writes/02.jpg'),
      //   name: '林晓慧',
      //   tag: '高级内容总监',
      //   intro: '集美大学艺术设计专业独立文案，精通拍摄剪辑孵化ip号视频播放破百万'
      // },
      {
        avatar: require('../images/trill/writes/03.jpg'),
        name: '王华炜',
        tag: '新媒体编导',
        intro: '从事自媒体行业成功孵化百万级IP并有破千万播放量的视频创作经验'
      },
      // {
      //   avatar: require('../images/trill/writes/07.jpg'),
      //   name: '郑颖烨',
      //   tag: '抖音编辑',
      //   intro: '从事编辑行业六年，担任过娱乐、财经编辑和新媒体运营。'
      // },
      {
        avatar: require('../images/trill/writes/05.jpg'),
        name: '陈坤平',
        tag: '新媒体内容策划',
        intro: '毕业于厦门华侨大学。文笔功底深厚，各类文风驾轻就熟，从业新媒体以来多次100W+爆款文章创作经历。'
      },
      {
        avatar: require('../images/trill/writes/08.jpg'),
        name: '蔡俊杰',
        tag: '抖音编辑',
        intro: '3年项目管控经验 曾服务于万科、世贸、禹州等全国五百强集团，致力于打造高品质内容，擅长深入挖掘品牌价值，提升产品核心竞争力。'
      },
      // {
      //   avatar: require('../images/trill/writes/04.jpg'),
      //   name: '林巧洪',
      //   tag: '新媒体内容策划',
      //   intro: '从事编辑文案工作三年，作品曾发布于抖音、微信公众号、今日头条等多个平台，参与过抖音ip的前期策划和后期脚本文案输出，成绩可观，经验丰富。'
      // },
      // {
      //   avatar: require('../images/trill/writes/06.jpg'),
      //   name: '游木珍',
      //   tag: '抖音编辑',
      //   intro: '毕业后从事新媒体行业三年，在运营、剪辑和文案方面都有丰富的经验，专业技能丰富，对多个平台的玩法规则都有深入的研究，运营过多个账号，-从0到10w+的粉丝增长，全网发布的作品阅读量突破千万+。'
      // }
    ]
  },
  makings:{
    title: '黑谷抖音事业部-后期团队',
    dec: '',
    teams: [
      {
        avatar: require('../images/trill/makings/01.jpg'),
        name: '黄聪',
        tag: '后期处理',
        intro: '从事新媒体编辑工作三年，有丰富的短视频制片后期调色，视频渲染，音乐剪辑经验。'
      },
      {
        avatar: require('../images/trill/makings/02.jpg'),
        name: '陈晖',
        tag: '后期处理',
        intro: '毕业于厦门大学数字与多媒体专业，从事自媒体行业已有8年，对后期的视频进行编辑和特效制作有丰富经验。'
      },
      {
        avatar: require('../images/trill/makings/03.jpg'),
        name: '刘厚俊',
        tag: '后期处理',
        intro: '从事抖音行业三年，参与抖音行业的知识内容制作，豆荚带货，信息流投放，抖音小店的直播。'
      }
    ]
  },
  operators: {
    title: '黑谷抖音事业部-运营推广团队',
    dec: '',
    teams: [
      {
        avatar: require('../images/trill/operators/01.jpg'),
        name: '杨小米',
        tag: '推广部总监',
        intro: '10年电商运营、竞价推广经验曾独自完成年产值千万的项目根据企业整体目标与营销策略设立战略规划带领30人团队完成亿量级投放任务，攻克全国市场'
      },
      {
        avatar: require('../images/trill/operators/02.jpg'),
        name: '肖长炳',
        tag: '运营部经理',
        intro: '多年运营经验，带领优化师团队操盘广告擅长多平台操作，为企业打造品牌矩阵通过数据分析为企业优化投放效果累计投放金额亿量级帮助多家企业实现高投产盈利'
      },
      {
        avatar: require('../images/trill/operators/03.jpg'),
        name: '陈茜',
        tag: '前字节资深优化师',
        intro: '多年广告运营经验，擅长操盘广告投放，新媒体运营拥有餐饮/汽车/教育/招商/二类电商等行业推广经验'
      },
      {
        avatar: require('../images/trill/operators/04.jpg'),
        name: '刘泽华',
        tag: '前字节高级优化师',
        intro: '综合行业推广经验丰富对各个行业的市场敏感玩转头条系平台以及多种推广方式广告投放策略及短视频内容营销具有较强的信息收集能力和数据分析能力'
      },
      {
        avatar: require('../images/trill/operators/05.jpg'),
        name: '吴玲',
        tag: '高级广告效果优化师',
        intro: '擅长收集用户数据，根据客户需求制定对应投放方案从事过餐饮/汽服/美容/教育行业的投放及运营帮助过多家企业获取前端线索消耗金额数千万，产出率高达1:5'
      },
      {
        avatar: require('../images/trill/operators/06.jpg'),
        name: '吴莎莎',
        tag: '高级广告效果优化师',
        intro: '擅长收集用户数据，根据客户需求制定对应投放方案从事过餐饮/汽服/美容/教育行业的投放及运营帮助过多家企业获取前端线索消耗金额数千万，产出率高达1:5'
      },
      {
        avatar: require('../images/trill/operators/07.jpg'),
        name: '赵峥旭',
        tag: '曾任企划主管',
        intro: '打造多产品线，月销售额破千万 ;精通抖音平台电商及直播玩法 ;广告投放策略及短视频内容营销 ;曾操盘修正药业、葫芦弟弟图书、银千惠 ;植护、紫色花语、卡尔顿、UGG等品牌抖音板块营销'
      },
      {
        avatar: require('../images/trill/operators/10.jpg'),
        name: '刘展鹏',
        tag: '高级广告效果优化师',
        intro: 'SEM搜索引擎广告，投放经验3年 ;操作百度账户消耗额千万量级 ;操作巨量账户消耗额千万 ;平均月消耗百万量级 ;最佳业绩完成个人指标230%，完成团队指标70%'
      },
      {
        avatar: require('../images/trill/operators/09.jpg'),
        name: '王华炜',
        tag: '高级策划师',
        intro: '擅长短视频剧本解析与创作 ;脚本翻拍视频播放量超千万 ;仅用两个月时间独自打造200W粉丝账号'
      },
      {
        avatar: require('../images/trill/operators/08.jpg'),
        name: '江丽芝',
        tag: '曾任字节跳动全国百强服务代言人',
        intro: '原恒安集团优化师 ;擅长电商渠道投放 ;根据不同产品制定推广方案 ;项目月消耗过百万'
      }
    ]
  }
}