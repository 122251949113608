






















import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class extends Vue {
  
  @Prop() title !: string
  @Prop() no !: number
  @Prop() content !: string

  get index(){
    return '0'+ (this.no+1)
  }

  get bg(){
    switch(this.no){
      case 0:
        return 'ONE'
      case 1:
        return 'TWO'
      case 2:
        return 'THREE'
      case 3:
        return 'FOUR'
      case 4:
        return 'FIVE'
      case 5:
        return 'SIX'
      case 6:
        return 'SEVEN'
      case 7:
        return 'EIGHT'
      case 8:
        return 'NINE'
      default:
        return 'TEN'
    }
  }

}
