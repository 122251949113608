







import { Component, Vue } from "vue-property-decorator"
import TrillQuestionItem from './trill-question-item.vue'
import trillData from '../../../assets/data/trill'

@Component({
  components: { TrillQuestionItem }
})
export default class extends Vue {
  private title = trillData.questions.title
  private list = trillData.questions.list
}
