








import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class extends Vue {
  
  @Prop() title !: string
  @Prop() icon !: string
  @Prop() content !: string

}
