








import { Component, Vue } from "vue-property-decorator"
import TrillServiceItem from './trill-service-item.vue'
import trillData from '../../../assets/data/trill'

@Component({
  components: { TrillServiceItem }
})
export default class extends Vue {
  private title = trillData.services.title
  private dec = trillData.services.dec
  private list = trillData.services.list
}
