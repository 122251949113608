




















import { Component, Vue } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import VBanner from '@/components/v-banner.vue';
import VCard from '@/components/v-card.vue'
import trillData from '../../assets/data/trill'
import TrillQuestion from './components/trill-question.vue'
import TrillService from './components/trill-service.vue'
import VPersonModule from '@/components/v-person-module.vue'

@Component({
  components: { vFreeApply,VBanner, VCard, TrillQuestion, TrillService, VPersonModule }
})
export default class extends Vue {
  private bannerData = trillData.banner
  private cardsData = trillData.cards
  private editors = trillData.editors
  private writers = trillData.writers
  private makings = trillData.makings
  private operators = trillData.operators
}
